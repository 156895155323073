import React from 'react';

function About() {
  return (
    <div className="About">
      <p>I started my career as a search engine marketer in 2008. Throughout the five years in that role, I increasingly saw a need for automating manual tasks that took a significant portion of a work day. That's when my journey to learn programming started. I started with Ruby on Rails for the backend and dove deeply into JavaScript for the UI. In quick succession, I created several tools and scripts to automate the time-consuming manual work and reaped the benefits of my new found hobby with my team.</p>

      <p>It didn't stop there. I continued to pursue building software and switched careers to UI development. Over the last several years, I've built websites using a variety of frameworks and many web applications for different clients. I've had the privilege of working on exciting and challenging projects that taught me not only how to <em>code</em> cleanly, but also how to <em>build</em> stable and efficient applications.</p>

      <p>My technical journey:</p>
      <ul>
        <li>It started with learning <span className="strong">JavaScript</span> (ES5/ES6) natively. Reading books upon books and moonlighting personal project after project.</li>
        <li>I became incredibly intrigued with running JS server-side with <span className="strong">Node.js</span> and picked up task-runners like <span className="strong">Grunt/Gulp</span> and bundlers like <span className="strong">Webpack</span>.</li>
        <li>I picked up <span className="strong">Vue.js</span> over an uneventful weekend and pitched it to my supervisor for my next project. After its success, we've used it for nearly every complex project since. In my personal time, I've forced myself to use <span className="strong">React</span> to deepen my skillset.</li>
        <li>I spent time learning our backend based on <span className="strong">PHP</span> and <span className="strong">MySQL</span> for the moments where I needed to make quick updates and fixes related to my work. This helped me see first-hand the value in <span className="strong">Object Oriented Programmming</span>.</li>
        <li>I got to use a variety of different <span className="strong">APIs</span> each with their own challenges and can appreciate a well designed API.</li>
      </ul>
    </div>
  );
}

export default About;