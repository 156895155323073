import React from 'react';
// import WorkList from './WorkList';
import DynamicSection from './DynamicSection';
import './Home.css';

function Home() {
  return (
    <main className="Home">
      <DynamicSection />
    </main>
  );
}

export default Home;
