import React from 'react';
import './InlineButton.css';

function InlineButton(props) {
  return (
    <button
      className={`InlineButton ${props.className}`}
      onClick={props.onClick}>
        {props.children}
    </button>
  );
}

export default InlineButton;