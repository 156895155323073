import React from 'react';
import InlineButton from './InlineButton';
import './ContactLinks.css';

const links = [
  { text: 'LinkedIn', handler() { window.location.href = 'https://www.linkedin.com/in/ross-joo-141bba13' }},
  { text: 'GitHub', handler() { window.location.href = 'https://github.com/rjoo' }},
  { text: 'Contact me', handler() { window.location.href = 'mailto:ross.joo@gmail.com' }}
];

function ContactLinks() {
  const linkItems = links.map(link => (<li key={link.text}><InlineButton onClick={link.handler}>{link.text}</InlineButton></li>));

  return (
    <ul className="ContactLinks">{linkItems}</ul>
  );
}

export default ContactLinks;
