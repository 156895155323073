import React from 'react';
import PortfolioItem from './PortfolioItem';
import portfolio from './portfolio.json';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePortfolioItemId: null
    };
  }

  toggleActiveItemId(id) {
    this.setState({
      // If it's the same ID, set to `null`
      activePortfolioItemId: this.state.activePortfolioItemId === id
        ? null
        : id
    });
  }

  render() {
    const portfolioList = portfolio.map(item =>
      (
        <PortfolioItem
          key={item.id}
          onToggle={this.toggleActiveItemId.bind(this, item.id)}
          isExpanded={this.state.activePortfolioItemId === item.id}
          title={item.title}
          description={item.description}
          tech={item.tech}
          image={item.image}
        />
      )
    );

    return (
      <div className="Portfolio">
        <p>Here are some of the things I've worked on:</p>

        <div className="Portfolio-container">
          {portfolioList}
        </div>
      </div>
    );
  }
}

export default Portfolio;