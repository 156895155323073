import React from 'react';
import About from './About';
import ContactLinks from './ContactLinks';
import InlineButton from './InlineButton';
import Portfolio from './Portfolio';
import { ReactComponent as ExpandLessIcon } from './icons/expand_less.svg';
import './DynamicSection.css'

const sections = {
  about: (<About key="about" />),
  portfolio: (<Portfolio key="portfolio" />)
};

class DynamicSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSection: null
    };
  }

  setActiveSection(section) {
    this.setState({ activeSection: section });
  }

  render() {
    let introClassName = 'DynamicSection-intro';
    let activeSection = null;
    let expandLessBtn = null;

    if (this.state.activeSection) {
      introClassName += ' DynamicSection-intro-collapsed';
      activeSection = (<div className="DynamicSection-active-content">{sections[this.state.activeSection]}</div>);
      expandLessBtn = (<button className="icon" onClick={this.setActiveSection.bind(this, null)}><ExpandLessIcon /></button>);
    }

    return (
      <section className="DynamicSection">
        <div className="DynamicSection-header DynamicSection-content">
          <div className="DynamicSection-heading">
            <h1>Hi, I'm Ross.</h1>
            {expandLessBtn}
          </div>

          {activeSection}
        </div>

        <div className={introClassName}>
          <div className="DynamicSection-content">
            <p>I'm a <InlineButton onClick={this.setActiveSection.bind(this, 'about')}>senior front-end developer</InlineButton> with several years of experience building web experiences and applications in the hospitality industry.</p>
            <p>I enjoy <InlineButton onClick={this.setActiveSection.bind(this, 'portfolio')}>coding and building</InlineButton> interesting things that challenge my current abilities. I take every opportunity to learn new skills, whether that be on the frontend or backend.</p>
            <p>Outside of work, I spend time with my family of two boys, play music and games, and tinker with everything.</p>
            
            <hr />
            <ContactLinks />
          </div>
        </div>
      </section>
    );
  }
}

export default DynamicSection;