import React from 'react';
import InlineButton from './InlineButton';
import { ReactComponent as MoreIcon } from './icons/more_horiz.svg';
import './ToggleButton.css';

function ToggleButton(props) {
  const className = `ToggleButton${props.isActive ? ' ToggleButton-active' : ''}`

  return (
    <span className={className}>
      <MoreIcon className="more-icon" />
      <InlineButton onClick={props.onToggle}>
        {props.children}
      </InlineButton>
    </span>
  );
}

export default ToggleButton;