import React from 'react';
import ToggleButton from './ToggleButton';
import './PortfolioItem.css';

function PortfolioItem(props) {
  let expandedContent;

  if (props.isExpanded) {
    expandedContent = (
      <div className="PortfolioItem-expanded">
        <div className="PortfolioItem-bg" style={{ backgroundImage: `url(${require('' + props.image)})`}}></div>
        <div className="PortfolioItem-description">
          <p>
            {props.description}
          </p>

          <div className="PortfolioItem-tech">
            <h5 className="PortfolioItem-tech-header">Tools and tech</h5>
            <ul className="PortfolioItem-techlist">
              {props.tech.map(tech => 
                <li key={tech} className="PortfolioItem-techlist-item">{tech}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="PortfolioItem">
      {/* Pre-render the image */}
      <div style={{ display: 'none', backgroundImage: `url(${require('' + props.image)})`}}></div>

      <div className="PortfolioItem-header">
        <ToggleButton
          isActive={props.isExpanded}
          onToggle={props.onToggle}>
            <h4 className="PortfolioItem-heading">
              {props.title}
            </h4>
        </ToggleButton>
      </div>
      {expandedContent}
    </div>
  );
}

export default PortfolioItem;